export enum ImageUnderlaySize {
    SMALL = 'small',
    LARGE = 'large',
    MEDIUM = 'medium',
}
export interface IImageWithUnderlayImageConfig {
    src: string;
    alt: string;
    isATFContent?: boolean;
    size?: ImageUnderlaySize;
}
