import { Component, Input, OnDestroy } from '@angular/core';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
import { IMenuWithDropdownConfig } from './menu-with-dropdown.interface';
import { menuCalloutType } from '../../enums/enums';
import { buttonType, fontSizes } from '../../../../enums/enums';
import { ImageBaseUrl } from '../../../../../core/constants/constants';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { DirectivesModule } from '../../../../directives/directives.module';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { OptimizedImageComponent } from '../../../../app-components/optimized-image/optimized-image.component';
import { ButtonsModule } from '../../../../ui-components/buttons/buttons.module';
import { ManagedOfficeFormComponent } from '../../../../app-components/form/managed-office-form/managed-office-form.component';
import { ICommercialLeasingFormConfig } from '../../../../app-components/form/commercial-leasing-form/commercial-leasing-form.interface';
import { clModalFormConfig } from '../../../../../modules/landing-pages/commercial-leasing/commercial-leasing-home/commercial-leasing-home.config';
import { CommercialLeasingFormSource } from '../../../../app-components/form/enums';
import { CommercialLeasingFormComponent } from '../../../../app-components/form/commercial-leasing-form/commercial-leasing-form.component';

@Component({
    selector: 'app-menu-with-dropdown',
    templateUrl: './menu-with-dropdown.component.html',
    styleUrls: ['./menu-with-dropdown.component.scss'],
    standalone: true,
    imports: [
        DirectivesModule,
        RouterLinkActive,
        RouterLink,
        NgTemplateOutlet,
        OptimizedImageComponent,
        ButtonsModule,
        ManagedOfficeFormComponent,
        AsyncPipe,
        CommercialLeasingFormComponent,
        NgIf,
    ],
})
export class MenuWithDropdownComponent implements OnDestroy {
    protected readonly menuCalloutType = menuCalloutType;
    calloutData = {
        [menuCalloutType.DOWNLOAD_APP]: {
            type: menuCalloutType.DOWNLOAD_APP,
            title: 'Download myHQ App',
            description: 'Connect with myHQ community',
            icon: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/download-app-flat.svg`,
                alt: 'download-app',
            },
            buttonConfig: {
                label: 'Download myHQ App',
                type: buttonType.OUTLINE,
                size: fontSizes.MEDIUM_MOB_DESK,
                icon: 'download-primary.svg',
            },
        },
        [menuCalloutType.COMMERCIAL_LEASING]: {
            type: menuCalloutType.COMMERCIAL_LEASING,
            title: 'Office/Commercial',
            description: 'Rent/Lease office space for your company',
            icon: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/products/commercial-leasing-flat.svg`,
                alt: 'manged-office',
            },
            buttonConfig: {
                label: 'Talk to an expert',
                type: buttonType.OUTLINE,
                size: fontSizes.MEDIUM_MOB_DESK,
            },
        },
    };
    clModalFormConfig: ICommercialLeasingFormConfig = {
        ...clModalFormConfig,
        showModal: false,
        formSource: CommercialLeasingFormSource.ALL_SOLUTIONS_HEADER,
    };

    constructor(
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
        private router: Router
    ) {}

    @Input() menu: IMenuWithDropdownConfig;

    showOptions = false;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    routerEventSub = this.utilsService.getRouterEvents$().subscribe(event => {
        this.showOptions = false;
    });

    toggleOptions(toggle: boolean) {
        this.showOptions = toggle;
    }

    handleClRequestCallbackCTAClick(state: boolean) {
        this.clModalFormConfig = {
            ...this.clModalFormConfig,
            showModal: state,
        };
    }

    handleCTAClick(type: menuCalloutType) {
        if (type === menuCalloutType.DOWNLOAD_APP) {
            this.router.navigate(['/app-download']);
        } else if (type === menuCalloutType.COMMERCIAL_LEASING) {
            this.handleClRequestCallbackCTAClick(true);
        }
        this.toggleOptions(false);
    }

    ngOnDestroy() {
        this.routerEventSub.unsubscribe();
    }
}
