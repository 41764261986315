import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { debounceTime, map, distinctUntilChanged, filter, share, throttleTime, take } from 'rxjs/operators';
import { Injectable, Inject, PLATFORM_ID, OnInit, Optional } from '@angular/core';
import { DatePipe, DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AppDownloadLink } from '../../constants/constants';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Observable, fromEvent, BehaviorSubject, Subject, from } from 'rxjs';
import { REQUEST } from '../../../../express.tokens';

enum DEVICE_OS {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
}
@Injectable({
    providedIn: 'root',
})
export class UtilsService implements OnInit {
    uniqueid = 0;
    isBrowser: boolean;
    isServer: boolean;
    webshareApiStatus = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    windowNavigator: any;
    userAgent: string;
    scroll$ = new Observable();
    scrollEnd$ = new Observable();
    devicePixelRatio = 1;
    throttledScroll$ = new Observable();
    debouncedScroll$ = new Observable();
    routerEvent$ = new Observable();
    cursorLeaveEvent$ = new Observable();
    windowInnerDimensionSubject = new BehaviorSubject({ height: 0, width: 0 });
    queryParamsSubject = new BehaviorSubject<Params>({});

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private router: Router,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        @Inject(DOCUMENT) private document: Document,
        @Optional() @Inject(REQUEST) httpRequest
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.isServer = isPlatformServer(platformId);

        this.route.queryParams.subscribe(this.queryParamsSubject);
        if (this.isBrowser) {
            this.windowNavigator = window.navigator;
            this.userAgent = this.windowNavigator.userAgent;
            if (this.windowNavigator && this.windowNavigator.share) {
                this.webshareApiStatus = true;
            }
            if (window && window.devicePixelRatio) {
                this.devicePixelRatio = window.devicePixelRatio;
            }
            this.scroll$ = fromEvent(window, 'scroll');
            this.scrollEnd$ = fromEvent(window, 'scrollend');
            this.debouncedScroll$ = this.scroll$.pipe(
                debounceTime(10),
                map(() => window.pageYOffset),
                distinctUntilChanged()
            );
            this.throttledScroll$ = this.scroll$.pipe(
                throttleTime(10),
                map(() => window.pageYOffset),
                distinctUntilChanged()
            );
            this.updateWindowInnerDimensionsSubject();
            this.cursorLeaveEvent$ = fromEvent(document.body, 'mouseleave').pipe(
                filter((position: MouseEvent) => position && position.pageY < 0),
                take(1),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                map(position => {
                    return true;
                })
            );
        } else {
            // Runs if its on Server
            this.userAgent = httpRequest.headers['user-agent'];
        }

        this.routerEvent$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            // tap((value) => console.log('Tapped router event', value)),
            share()
        );
    }

    private updateWindowInnerDimensionsSubject() {
        // TODO: re-write function to start with current value && and set observable like $scroll is being set
        this.windowInnerDimensionSubject.next({ height: window.innerHeight, width: window.innerWidth });
        fromEvent(window, 'resize')
            .pipe(debounceTime(100))
            .subscribe(() => {
                this.windowInnerDimensionSubject.next({ height: window.innerHeight, width: window.innerWidth });
            });
    }

    getScroll(): {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scroll$: Observable<any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throttledScroll$: Observable<any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        debouncedScroll$: Observable<any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scrollEnd$: Observable<any>;
    } {
        return {
            scroll$: this.scroll$,
            throttledScroll$: this.throttledScroll$,
            debouncedScroll$: this.debouncedScroll$,
            scrollEnd$: this.scrollEnd$,
        };
    }

    getScrollBarWidth() {
        let scrollBarWidth = 0;
        if (this.getDeviceInfo().isBrowser) {
            scrollBarWidth = window.innerWidth - this.document.body.clientWidth;
        }
        return scrollBarWidth;
    }

    getRegexPatternMatch(regexArray: RegExp[], input: string): number {
        return regexArray.findIndex(regex => {
            return regex.test(input);
        });
    }

    getWindowInnerDimensions() {
        return this.windowInnerDimensionSubject;
    }

    getQueryParams() {
        return this.queryParamsSubject;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRouterEvents$(): Observable<any> {
        return this.routerEvent$;
    }

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle, @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    // TODO:: Implement Fallback to webshare api
    webshareApi(title: string, text: string, url: string) {
        if (this.isBrowser) {
            if (this.windowNavigator && this.windowNavigator.share) {
                this.windowNavigator.share({ title, text, url });
            }
        }
    }

    isIntersectionObserverSupported() {
        return window && 'IntersectionObserver' in window;
    }

    isLowSpeedNetwork() {
        let isLowSpeedNetwork = false;
        if (this.isBrowser) {
            isLowSpeedNetwork = ['slow-2g', '2g', '3g'].indexOf(this.windowNavigator?.connection?.effectiveType) > -1;
        }
        return isLowSpeedNetwork;
    }

    getOffsetTop(element) {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    // we have to move this functionality in directive, temp use only
    scrollToTargetAdjusted(element: HTMLElement, headerOffset = 0, callback?: () => void) {
        if (this.isBrowser) {
            const threshold = 2;
            const ypos = Math.max(this.getOffsetTop(element) - element.scrollTop + element.clientTop - headerOffset, 0);
            const onScroll = () => {
                if (Math.abs(window.pageYOffset - ypos) <= threshold) {
                    window.removeEventListener('scroll', onScroll);
                    callback?.();
                }
            };
            if (typeof callback === 'function') {
                window.addEventListener('scroll', onScroll);
            }
            window.scrollTo({ top: ypos, behavior: 'smooth' });
        }
    }

    scrollToTop(topOffset = 0, callback: () => void) {
        if (this.isBrowser) {
            const fixedOffset = topOffset.toFixed();
            const onScroll = () => {
                // When scrolling is done execute the callback function
                if (window.pageYOffset.toFixed() === fixedOffset) {
                    window.removeEventListener('scroll', onScroll);
                    callback();
                }
            };
            if (typeof callback === 'function') {
                window.addEventListener('scroll', onScroll);
            }
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
    }

    getWebShareApiStatus() {
        return this.webshareApiStatus;
    }

    getUniqueId() {
        this.uniqueid++;
        return this.uniqueid;
    }

    getDevicePixelRatio() {
        return Math.ceil(this.devicePixelRatio);
    }

    getDeviceInfo() {
        const deviceInfo = {
            isMobile: null,
            os: null,
            isBrowser: this.isBrowser,
        };

        if (this.userAgent?.indexOf('iPhone') !== -1) {
            deviceInfo.isMobile = true;
            deviceInfo.os = DEVICE_OS.IOS;
        } else if (this.userAgent?.indexOf('Android') !== -1) {
            deviceInfo.isMobile = true;
            deviceInfo.os = DEVICE_OS.ANDROID;
        }

        return deviceInfo;
    }

    // this function return app download link on the basis of platform
    getAppDownloadLink() {
        const deviceInfo = this.getDeviceInfo();
        let appDownloadLink;
        if (deviceInfo.os) {
            appDownloadLink = AppDownloadLink[deviceInfo.os];
        }
        return appDownloadLink;
    }

    getUserAgent() {
        return this.userAgent;
    }

    isiOSSafari() {
        if (this.userAgent) {
            return (
                /iP(ad|od|hone)/i.test(this.userAgent) &&
                /WebKit/i.test(this.userAgent) &&
                !/(CriOS|FxiOS|OPiOS|mercury)/i.test(this.userAgent)
            );
        }
        return false;
    }

    isStringArraysEqual(arr1: string[], arr2: string[]): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }
        arr1 = arr1.slice().sort();
        arr2 = arr2.slice().sort();
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }

    isArraysOfObjectsEqual = (array1, array2) => {
        if (array1?.length !== array2?.length) {
            return false;
        }

        // Sort arrays to ensure consistent order for comparison
        const sortedArray1 = [...array1].sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));
        const sortedArray2 = [...array2].sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));

        for (let i = 0; i < sortedArray1.length; i++) {
            if (!this.areObjectsEqual(sortedArray1[i], sortedArray2[i])) {
                return false;
            }
        }

        return true;
    };

    areObjectsEqual = (obj1, obj2) => {
        // checks if it's a string, boolean, etc.
        if (obj1 === obj2) {
            return true;
        }

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!this.areObjectsEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    };

    getFormattedDate(date: Date, format?: string) {
        return this.datePipe.transform(date, format || `dd-MM-yyyy`);
    }

    pluralise(inputString: string, count: number, suffix = 's') {
        if (count > 1) {
            return `${inputString}${suffix}`;
        }

        return inputString;
    }

    removeBlankAttributes(obj) {
        const result = {};
        for (const key in obj) {
            if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
                result[key] = obj[key];
            }
        }
        return result;
    }

    getMonthName(textCase?: string) {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        const monthNamesLower = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ];
        const d = new Date();
        if (textCase === 'lowercase') {
            return monthNamesLower[d.getMonth()];
        }
        return monthNames[d.getMonth()];
    }
}
