import { ElementRef } from '@angular/core';
import { IFormsConfig as IFormFieldsConfig } from '../../../ui-components/forms/forms.component';
import { IDedicatedBaseFormConfig } from '../dedicated-form/dedicated-form.interface';
import { IFormHeaderConfig } from '../../../ui-components/form-header/form-header.interface';
import { ICity } from '../../../interfaces/location.interface';

export enum CommercialLeasingFormType {
    MODAL = 'MODAL',
    DEFAULT = 'DEFAULT',
    INLINE = 'INLINE',
}

export interface ICommercialLeasingFormConfig {
    mwebClose: IDedicatedBaseFormConfig;
    mwebOpen: IDedicatedBaseFormConfig;
    desk: IDedicatedBaseFormConfig;
    defaultCampaign?: string;
    city?: ICity;
    formType: CommercialLeasingFormType;
    stickyFormConfig?: {
        showAfterTopRef: ElementRef;
        topSpacing: number;
        hideAfterBottomRef: ElementRef;
        bottomSpacing: number;
    };
    submitButtonLabel?: string;
    formSource: string;
    formFieldsConfig?: IFormFieldsConfig;
    showFormOnMobile?: boolean;
    formHeaderConfig?: IFormHeaderConfig;
    showModal?: boolean;
    isWhatsappForm?: boolean;
    initialWhatsappText?: string;
}
