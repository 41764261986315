import { Directive, EventEmitter, Input, NgZone, OnDestroy, Output, Renderer2 } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { IStickySectionConfig } from '../../interfaces/sticky-section.interface';
import { StickyService } from '../../services/sticky/sticky.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Subscription, tap } from 'rxjs';

@Directive({
    standalone: true,
    selector: '[appStickySection]',
})
export class StickySectionDirective implements OnDestroy {
    // TODO:: No Directive Related Used (elementRef), Allow Classes directly on the element directive is
    // applied. Or Make This a function instead maybe

    scrollSubscription: Subscription;

    stickySectionConfig: IStickySectionConfig;
    @Input('stickySectionConfig') set _stickySectionConfig(stickySectionConfig: IStickySectionConfig) {
        this.stickySectionConfig = stickySectionConfig;
        this.addStickyConfig(stickySectionConfig);
    }
    constructor(
        private ngZone: NgZone,
        private utilsService: UtilsService,
        private renderer: Renderer2,
        private stickyService: StickyService
    ) {}

    @Output() scrollDirection = new EventEmitter<'UP' | 'DOWN'>();
    @Output() stickyChange = new EventEmitter<boolean>();

    ngOnDestroy() {
        this.scrollSubscription?.unsubscribe();
    }

    addStickyConfig(stickySectionConfig: IStickySectionConfig) {
        if (this.utilsService.isBrowser && stickySectionConfig) {
            let prevScrollY = 0;
            this.ngZone.runOutsideAngular(() => {
                // TODO :: we have to find a solution to get the updated document height after this
                // directive initiated bcz after API's response document's height updates
                this.scrollSubscription = this.utilsService
                    .getScroll()
                    .debouncedScroll$.pipe(
                        map(scrollY => {
                            let position = 'DOWN';
                            let direction: 'UP' | 'DOWN' = prevScrollY < scrollY ? 'UP' : 'DOWN';

                            prevScrollY = scrollY;

                            const { topPosition, bottomPosition } = this.getPosition(stickySectionConfig);
                            const stickyContainerHeight =
                                this.stickyService.getStickyContainerRef().nativeElement.clientHeight;

                            const inAfter = Math.max(topPosition - stickyContainerHeight, 0);

                            if (scrollY > inAfter && scrollY < bottomPosition) {
                                position = 'IN';
                            }

                            this.scrollDirection.emit(direction);

                            return position;
                        }),
                        distinctUntilChanged()
                    )
                    .subscribe((position) => {
                        const isSticky = position === 'IN';
                        this.stickyChange.emit(isSticky);

                        if (isSticky) {
                            stickySectionConfig.classInfo.map(element => {
                                if (element.containerRef) {
                                    if (stickySectionConfig.useClass) {
                                        this.renderer.addClass(element.containerRef.nativeElement, element.className);
                                    } else {
                                        this.stickyService.addElementToSticky(
                                            element.containerRef,
                                            stickySectionConfig.containerRef,
                                            element.className
                                        );
                                    }
                                }
                            });
                        } else {
                            stickySectionConfig.classInfo.map(element => {
                                if (element.containerRef) {
                                    if (stickySectionConfig.useClass) {
                                        this.renderer.removeClass(
                                            element.containerRef.nativeElement,
                                            element.className
                                        );
                                    } else {
                                        this.stickyService.removeElement(
                                            element.containerRef,
                                            stickySectionConfig.containerRef,
                                            element.className
                                        );
                                    }
                                }
                            });
                        }
                    });
            });
        }
    }

    getPosition(stickySectionConfig: IStickySectionConfig) {
        const topNativeElement =
            stickySectionConfig.topElement && stickySectionConfig.topElement.ref
                ? stickySectionConfig.topElement.ref.nativeElement
                : '';
        const bottomNativeElement =
            stickySectionConfig.bottomElement && stickySectionConfig.bottomElement.ref
                ? stickySectionConfig.bottomElement.ref.nativeElement
                : '';
        let topPositionAbsolute = topNativeElement ? topNativeElement.offsetTop : 0;
        topPositionAbsolute += stickySectionConfig.topElement ? stickySectionConfig.topElement.resetPixel : 0;
        let bottomPositionAbsolute = bottomNativeElement
            ? bottomNativeElement.offsetTop + bottomNativeElement.offsetHeight
            : document.body.offsetHeight;
        bottomPositionAbsolute += stickySectionConfig.bottomElement ? stickySectionConfig.bottomElement.resetPixel : 0;
        return {
            topPosition: topPositionAbsolute,
            bottomPosition: bottomPositionAbsolute,
        };
    }
}
