export enum CommercialLeasingFormSource {
    HERO = 'Hero',
    TESTIMONIAL = 'Testimonial',
    STICKY = 'Sticky',
    CLIENTS_BANNER = 'ClinetsBanner',
    FIVE_STEPS = 'FiveSteps',
    CITY_LOCALITY = 'CityLocality',
    WHY_MYHQ = 'WhyMyhq',
    OFFICE_SPACE_SOLUTION = 'OfficeSpaceSolution',
    CARDS = 'Cards',
    MAP_SPACE_CARD = 'MapSpaceCard',
    CONTACT_EXPERT = 'ContactExpert',
    REQUEST_CALLBACK = 'RequestCallback',
    ABOUT_BRAND = 'AboutBrand',
    WHY_MYHQ_BANNER = 'WhyMyhqBanner',
    PRICING_CARD = 'PricingCard',
    ALL_SOLUTIONS_HEADER = 'AllSolutionsHeader',
    LP_MIDDLE_CTA = 'middleCTA',
    WHATSAPP_CALLOUT = 'WhatsAppCallout',
}
