import { UrlGeneratorService } from '../../../services/url-generator/url-generator.service';
import { Injectable } from '@angular/core';
import { LocationService } from '../../../services/location/location.service';
import { SelectCityService } from '../../../services/select-city/select-city.service';
import { catchError, filter, map, startWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommonService } from '../../../services/common/common.service';
import { ImageBaseUrl } from '../../../../core/constants/constants';
import { productsMap } from '../../../enums/enums';
import { ICity } from '../../../interfaces/location.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IHttpResponse } from '../../../interfaces/HTTPResponse.interface';
import { ErrorService } from '../../../../core/services/error/error.service';
import {
    IFooterCmsAPIPayload,
    IFooterCmsMetaDataConfig,
} from '../../footer-cms-metadata/footer-cms-metadata.interfaces';
import { ISecondaryFooterResponse } from '../../../interfaces/footer.interface';

@Injectable({
    providedIn: 'root',
})
export class FooterService {
    // TODO:: Move static urls to constants
    constructor(
        private locationService: LocationService,
        private selectCityService: SelectCityService,
        private commonService: CommonService,
        private urlGeneratorService: UrlGeneratorService,
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    ImageBaseUrl = ImageBaseUrl;

    selectedCity$ = this.selectCityService.getSelectedCitySync();

    primaryFooterLinks = {
        myhqGeneralLinks: {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/logo/logo-new-flat.svg`,
            items: [
                { label: 'Careers', url: of('https://myhq.keka.com/careers'), isExternal: true },
                { label: 'Blog', url: of('https://digest.myhq.in/'), isExternal: true },
                { label: 'Testimonials', url: of('/testimonials'), isExternal: false },
                { label: 'Case Studies', url: of('/case-studies'), isExternal: false },
                { label: 'Terms & Conditions', url: of(`/termsncondition`), isExternal: false },
                { label: 'Privacy Policy', url: of(`/privacy`), isExternal: false },
                { label: 'Refund', url: of(`/refund`), isExternal: false },
                {
                    label: 'FAQs',
                    url: of(`https://help.myhq.in/portal/en/home?customThemeId=215431000104531001`),
                    isExternal: true,
                },
                { label: 'About Us', url: of(`/about-us`), isExternal: false },
                { label: 'Contact Us', url: of(`/contact`), isExternal: false },
            ],
        },
        myhqProducts: {
            title: 'Products',
            items: [
                {
                    productObj: productsMap.FLEXI,
                    label: 'Day Pass',
                    url: this.urlGeneratorService.flexiProductUrl,
                    isExternal: false,
                    comingSoon: false,
                },
                {
                    productObj: productsMap.DEDICATED,
                    label: 'Coworking Space',
                    url: this.urlGeneratorService.dedicatedProductUrl,
                    isExternal: false,
                    comingSoon: false,
                },
                {
                    productObj: productsMap.COMMERCIAL_LEASING,
                    label: 'Office/Commercial',
                    url: this.urlGeneratorService.commercialLeasingListingUrl,
                    isExternal: false,
                    comingSoon: false,
                },
                {
                    productObj: productsMap.MANAGED_OFFICE,
                    label: 'Managed Office',
                    url: this.urlGeneratorService.managedOfficeListingUrl,
                    isExternal: false,
                    comingSoon: false,
                },
                {
                    productObj: productsMap.CORPORATE_FLEXI,
                    label: 'Team Plan',
                    url: this.urlGeneratorService.corporateFlexiProductUrl,
                    isExternal: false,
                    comingSoon: false,
                },
                {
                    productObj: productsMap.VIRTUAL_OFFICE,
                    label: 'Virtual Office',
                    url: this.urlGeneratorService.virtualOfficeUrl,
                    isExternal: false,
                    comingSoon: false,
                },
            ],
        },
        myhqCommunity: {
            title: 'myHQ Community',
            items: [
                { label: 'Community benefits', url: of(`/community-benefits`), isExternal: false },
                { label: 'Reopening Office?', url: of(`/sp/opening-office-post-covid`), isExternal: false },
                { label: 'Refer & Earn', url: of(`/referrals`), isExternal: false },
            ],
        },
        myhqPartnership: {
            title: 'Partnerships',
            items: [{ label: 'List Your Space', url: of(`/list-space`), isExternal: true }],
        },
    };

    getPrimaryFooterLinks() {
        return this.selectedCity$
            .pipe(startWith({ isDefault: true }))
            .pipe(filter(({ city, isDefault }: { city?: ICity; isDefault?: boolean }) => !!(city || isDefault)))
            .pipe(
                map(({ city, isDefault }: { city?: ICity; isDefault?: boolean }) => {
                    // For the default case no city will be there and for the rest of the case is isDefault will be undefined
                    if (city?.products || isDefault) {
                        let myHQProductItems = this.primaryFooterLinks.myhqProducts.items;
                        // Check for coming soon and product availability if this is not the initial execution
                        if (!isDefault) {
                            myHQProductItems = this.primaryFooterLinks.myhqProducts.items.map(product => {
                                if (
                                    city.products.some(
                                        productSupportedByCity =>
                                            productSupportedByCity.key === product.productObj.productNameBackend
                                    )
                                ) {
                                    return product;
                                } else {
                                    return {
                                        ...product,
                                        comingSoon: true,
                                    };
                                }
                            });
                        }
                        return [
                            { ...this.primaryFooterLinks.myhqGeneralLinks },
                            { ...this.primaryFooterLinks.myhqProducts, items: myHQProductItems },
                            { ...this.primaryFooterLinks.myhqCommunity },
                            { ...this.primaryFooterLinks.myhqPartnership },
                        ];
                    }
                })
            );
    }

    getSecondaryFooterLinks(): Observable<ISecondaryFooterResponse> {
        const url = `${environment.baseUrl}/product/web/seo/products-category-links`;
        return this.http.get<IHttpResponse<ISecondaryFooterResponse>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in schedule visit API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getCMSMetaData(payload: IFooterCmsAPIPayload): Observable<{ content: IFooterCmsMetaDataConfig[] }> {
        // encodeURIComponent is required to safely pass an entire url in the slug
        const url = `${environment.baseUrl}/content/web/page?slug=${encodeURIComponent(payload.slug)}`;
        return this.http.get<IHttpResponse<{ content: IFooterCmsMetaDataConfig[] }>>(url).pipe(
            map(res => {
                if (res) {
                    return res.data;
                }
            }),
            catchError(this.errorService.handleError)
        );
    }
}
