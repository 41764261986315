import { UrlGeneratorService } from '../../services/url-generator/url-generator.service';
import { buttonType, HeaderSearchVariant, productKeys, products, productsMap } from '../../enums/enums';
import { HeaderService } from './services/header.service';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { ImageBaseUrl } from '../../../core/constants/constants';
import { menuCalloutType, menuEnum, menuType } from './enums/enums';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { combineLatest, of } from 'rxjs';
import { ICity } from '../../interfaces/location.interface';
import { LocationWorkspaceSearchConfig } from '../location/location-workspace-search/location-workspace-search.types';
import { LocationWorkspaceSearchService } from '../../services/location-workspace-search.service';
import { LocationWorkspaceMobileModalTriggerComponent } from '../location/location-workspace-mobile-modal-trigger/location-workspace-mobile-modal-trigger.component';
import { ILocationWorkspaceHeaderSearchMob } from '../location/location-workspace-mobile-modal-trigger/location-workspace-mobile-modal-trigger.types';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { IHeaderSearchConfig } from './components/header-search/header-search.types';
import { DomSanitizer } from '@angular/platform-browser';
import { ISearchConfig } from '../search/search.interface';
import { guideForOfficeSpace, moreMenuItem } from './commom-header.config';
import {
    IMenuWithDropdownConfig,
    IMenuWithDropdownMenuItem,
    IMenuWithDropdownSubMenu,
} from './components/menu-with-dropdown/menu-with-dropdown.interface';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Logo from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/logo/logo-flat.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MenuIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/menu.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CrossIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/cross.svg';
import { CommonModule } from '@angular/common';
import { ReactiveStickyHeaderDirective } from '../../directives/reactive-sticky-header/reactive-sticky-header.directive';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SearchComponent } from '../search/search.component';
import { MenuWithDropdownComponent } from './components/menu-with-dropdown/menu-with-dropdown.component';
import { LocationDropdownComponent } from './components/location-dropdown/location-dropdown.component';
import { ModalsModule } from '../../ui-components/modals/modals.module';
import { LoginSignupMenuItemComponent } from './components/login-signup-menu-item/login-signup-menu-item.component';
import { LoginSignupFlexiMenuItemComponent } from './components/login-signup-flexi-menu-item/login-signup-flexi-menu-item.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { LoginModalComponent } from '../../../modules/auth/components/login-modal/login-modal.component';
import { MobileOtpModalComponent } from '../../../modules/mobile-otp-auth/components/mobile-otp-login-modal/mobile-otp-modal.component';
import { ExpandableLinksPanelComponent } from './components/expandable-links-panel/expandable-links-panel.component';
import { SelectCityComponent } from '../select-city/select-city.component';

@Component({
    selector: 'app-common-header',
    templateUrl: './common-header.component.html',
    styleUrls: ['./common-header.component.scss'],
    host: {
        // NOTE: UI flicker observed on MR/Flexi listing pages with the Login/Signup menu item
        // This flicker is related to `updateMenus` function because when the `menu` variable's state is manually
        // assigned to the desired value, it works without flicker.
        ngSkipHydration: 'true',
    },
    standalone: true,
    imports: [
        CommonModule,
        ReactiveStickyHeaderDirective,
        MobileOtpModalComponent,
        RouterLink,
        SelectCityComponent,
        SearchComponent,
        HeaderSearchComponent,
        MenuWithDropdownComponent,
        LocationDropdownComponent,
        ModalsModule,
        RouterLinkActive,
        LocationWorkspaceMobileModalTriggerComponent,
        LoginModalComponent,
        LoginSignupMenuItemComponent,
        LoginSignupFlexiMenuItemComponent,
        LogoutButtonComponent,
        ExpandableLinksPanelComponent,
    ],
})
export class CommonHeaderComponent implements OnInit, OnDestroy {
    productKeys = productKeys;
    device = this.utilsService.getDeviceInfo();

    constructor(
        private selectCityService: SelectCityService,
        private utilsService: UtilsService,
        private headerService: HeaderService,
        private urlGeneratorService: UrlGeneratorService,
        private locationWorkspaceSearchService: LocationWorkspaceSearchService,
        private sanitizer: DomSanitizer
    ) {}

    // TODO:: move static redirect url to constants
    @Output() headerBtnClicked = new EventEmitter();
    commonIconsUrl = CommonIconsUrl;
    HeaderSearchVariant = HeaderSearchVariant;
    logoIcon = this.sanitizer.bypassSecurityTrustHtml(Logo);
    menuEnum = menuEnum;
    menuType = menuType;
    buttonTypeEnum = buttonType;
    showCityChange = false;
    isLogoClickable = false;
    selectedCity: ICity;
    isAnimated = false;
    showMenuIconOnMobile = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productObj: any;
    homepageUrl = this.urlGeneratorService.homepage;
    showHeaderSearch = true;
    headerSearchVariant: HeaderSearchVariant = HeaderSearchVariant.DEFAULT;
    // showHeaderMobileSearch = true;
    showHeaderSearchInSticky = false;
    selectedProduct = productsMap.DEDICATED;

    flexiConfig = {
        id: menuEnum.FLEXI,
        imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/day-pass.svg`,
        title: 'Day Pass',
        description: 'Desks-by-the-day in coworking spaces',
        redirectUrl: this.urlGeneratorService.flexiProductUrl,
        comingSoon: false,
        show: false,
        type: menuType.LINK,
    };

    corporateFlexiConfig = {
        id: menuEnum.CORPORATE_FLEXI,
        title: 'Team Plan',
        description: 'Flexible day pass for remote & hybrid teams managed by one account',
        imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/team-plan.svg`,
        type: menuType.LINK,
        redirectUrl: this.urlGeneratorService.corporateFlexiProductUrl,
        show: false,
        comingSoon: false,
    };

    dedicatedConfig = {
        id: menuEnum.DEDICATED,
        title: 'Coworking Space',
        description: 'Rent dedicated seats and cabins in fully-equipped coworking spaces',
        imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/coworking.svg`,
        type: menuType.LINK,
        redirectUrl: this.urlGeneratorService.dedicatedProductUrl,
        show: false,
        comingSoon: false,
    };
    managedOfficeConfig = {
        id: menuEnum.MANAGED_OFFICE,
        title: 'Managed Office',
        description: 'Dedicated office space managed by a provider',
        imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/managed-office.svg`,
        type: menuType.LINK,
        redirectUrl: this.urlGeneratorService.managedOfficeListingUrl,
        show: false,
        comingSoon: false,
    };
    commercialLeasingConfig = {
        id: menuEnum.COMMERCIAL_LEASING,
        title: 'Office/Commercial',
        description: 'Rent/Lease office space for your company',
        imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/commercial-leasing.svg`,
        type: menuType.LINK,
        redirectUrl: this.urlGeneratorService.commercialLeasingListingUrl,
        show: false,
        comingSoon: false,
    };

    virtualOfficeConfig = {
        id: menuEnum.VIRTUAL_OFFICE,
        title: 'Virtual Office',
        description: 'Registration & GST solutions for new businesses',
        imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/virtual-office.svg`,
        type: menuType.LINK,
        redirectUrl: this.urlGeneratorService.virtualOfficeUrl,
        show: false,
        comingSoon: false,
    };

    meetingRoomConfig = {
        id: menuEnum.MEETING_ROOM,
        title: 'Meeting Rooms',
        description: 'Meeting, conference and training rooms for teams',
        imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/meeting-room.svg`,
        type: menuType.LINK,
        redirectUrl: this.urlGeneratorService.meetingRoomListingUrl,
        show: false,
        comingSoon: false,
    };

    menuIcon = this.sanitizer.bypassSecurityTrustHtml(MenuIcon);
    crossIcon = this.sanitizer.bypassSecurityTrustHtml(CrossIcon);

    menus: IMenuWithDropdownConfig[] = [
        {
            id: menuEnum.HOME,
            title: 'Home',
            type: menuType.LINK,
            redirectUrl: this.urlGeneratorService.homepage,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/home-grey.svg`,
            iconUrlSelected: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/home-primary-flat.svg`,
            show: false,
        },
        {
            id: menuEnum.SEARCH,
            title: `Search workspace`,
            type: menuType.CLICKABLE_LINK_BTN,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/search.svg`,
            showArrow: true,
            show: false,
        },
        {
            id: menuEnum.REQUEST_CALLBACK,
            title: 'Request callback',
            type: menuType.CLICKABLE_LINK_BTN,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/call-grey.svg`,
            show: false,
        },
        { ...this.getCorporateFlexiConfig() },
        { ...this.getVirtualOfficeConfig() },
        {
            id: menuEnum.LIST_SPACE,
            title: 'List your space',
            type: menuType.LINK,
            redirectUrl: of(`/list-space`),
            isExternalUrl: true,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/list-space.svg`,
            iconUrlSelected: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/list-space-primary.svg`,
            show: false,
        },
        {
            id: menuEnum.ALL_SOLUTIONS,
            title: 'All Solutions',
            description: '',
            type: menuType.DROPDOWN,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/all-solutions.svg`,
            show: false,
            comingSoon: false,
            calloutType: menuCalloutType.COMMERCIAL_LEASING,
            subMenus: [
                {
                    id: menuEnum.OD,
                    title: 'Book on-demand',
                    menuItems: [
                        { ...this.getFlexiConfig() },
                        { ...this.getMeetingRoomConfig() },
                        { ...this.getCorporateFlexiConfig() },
                    ],
                },
                {
                    id: menuEnum.RENT,
                    title: 'Rent longer-term',
                    menuItems: [
                        { ...this.getDedicatedConfig() },
                        { ...this.getCommercialLeasingConfig() },
                        { ...this.getManagedOfficeConfig() },
                        { ...this.getVirtualOfficeConfig() },
                    ],
                },
            ],
        },
        {
            id: menuEnum.PRODUCTS,
            title: 'All Solutions',
            description: '',
            type: menuType.DROPDOWN,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/all-solutions.svg`,
            show: false,
            comingSoon: false,
            subMenus: [
                {
                    id: menuEnum.OD,
                    title: 'Book on-demand',
                    menuItems: [
                        { ...this.getFlexiConfig() },
                        { ...this.getMeetingRoomConfig() },
                        { ...this.getCorporateFlexiConfig() },
                    ],
                },
                {
                    id: menuEnum.RENT,
                    title: 'Rent longer-term',
                    menuItems: [
                        { ...this.getDedicatedConfig() },
                        { ...this.getCommercialLeasingConfig() },
                        { ...this.getManagedOfficeConfig() },
                        { ...this.getVirtualOfficeConfig() },
                    ],
                },
            ],
            mobileSingleMenu: true,
        },
        {
            id: menuEnum.LOGIN_SIGNUP,
            type: menuType.LOGIN_SIGNUP,
            show: false,
            comingSoon: false,
        },
        {
            id: menuEnum.LOGIN_SIGNUP_FLEXI,
            type: menuType.LOGIN_SIGNUP_FLEXI,
            show: false,
            comingSoon: false,
        },
        {
            id: menuEnum.LOCATIONS,
            title: 'Locations',
            type: menuType.LOCATION_DROPDOWN,
            show: false,
        },
        { ...moreMenuItem },
        { ...guideForOfficeSpace },
        {
            id: menuEnum.CONTACT_US,
            title: 'Share requirement',
            redirectUrl: of(`/contact`),
            type: menuType.LINK,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/contact.svg`,
            show: false,
        },
        {
            id: menuEnum.OFFICE_SPACE,
            title: 'Office space',
            redirectUrl: this.urlGeneratorService.enterprisesProductUrl,
            type: menuType.LINK,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/office-space.svg`,
            show: false,
        },
        {
            id: menuEnum.FREE_TRIAL,
            title: 'Try day pass',
            redirectUrl: this.urlGeneratorService.flexiListingUrl,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/calendar-grey.svg`,
            iconUrlSelected: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/calendar-primary.svg`,
            type: menuType.LINK_BTN,
            btnType: buttonType.PRIMARY,
            show: false,
        },
        {
            id: menuEnum.LOGIN,
            title: 'Login',
            redirectUrl: this.urlGeneratorService.teamDashboardLoginUrl,
            type: menuType.LINK_BTN,
            btnType: buttonType.OUTLINE,
            show: false,
            isExternalUrl: true,
        },
        {
            id: menuEnum.SIGNUP,
            title: 'Login / Sign up',
            redirectUrl: this.urlGeneratorService.teamDashboardSignUpUrl,
            type: menuType.LINK_BTN,
            btnType: buttonType.PRIMARY,
            show: false,
            isExternalUrl: true,
        },
        {
            id: menuEnum.MY_SHORTLIST,
            title: 'My Shortlists',
            type: menuType.LINK,
            redirectUrl: this.urlGeneratorService.myShortlistsUrl,
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/shortlist-icon.svg`,
            show: false,
        },
        {
            id: menuEnum.LOGOUT_BTN,
            type: menuType.LOGOUT_BTN,
            show: false,
        },
        {
            id: menuEnum.LOGOUT_BTN_FLEXI,
            type: menuType.LOGOUT_BTN_FLEXI,
            show: false,
        },
        {
            id: menuEnum.DOWNLOAD_APP,
            title: 'Download myHQ App',
            type: menuType.LINK,
            redirectUrl: of('/app-download'),
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/download-app.svg`,
            show: false,
        },
    ];

    searchSolutionsDropDownMenu = {
        id: menuEnum.HEADER_SEARCH,
        title: 'Flexi Desk',
        description: '',
        type: menuType.DROPDOWN,
        show: false,
        subMenus: [{ ...this.getFlexiConfig() }, { ...this.getDedicatedConfig(), isSelected: true }],
    };

    locationWorkspaceSearchConfig: LocationWorkspaceSearchConfig =
        this.headerService.getLocationWorkspaceSearchConfig(products.INDIVIDUAL);
    locationWorkspaceHeaderSearchMob: ILocationWorkspaceHeaderSearchMob;

    headerSearchConfig: IHeaderSearchConfig = {
        showProductSwitch: false,
        menu: this.searchSolutionsDropDownMenu,
        locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
    };
    detailedHeaderSearchConfig: ISearchConfig = {
        useAsHeaderComponent: true,
        headerConfig: {
            focusOnInit: true,
            bannerConfig: {
                show: true,
                label: 'Find your perfect workspace',
            },
        },
        // if there is no product selected, keep the default as Fixed Desk
        selectedProduct: productsMap[productKeys.DEDICATED],
    };

    navbarClassLogic = {
        Up: { Add: ['fixed-elem', 'top-0'], Remove: ['top-minus-70'] },
        Down: { Add: ['top-minus-70'], Remove: ['top-0', 'top-53'] },
        Top: { Add: ['top-53'], Remove: ['top-minus-70', 'top-0', 'fixed-elem'] },
    };
    menuModalMobile = {
        isOpen: false,
    };

    cssConfig = {
        [buttonType.OUTLINE]: ['btn-outline'],
    };

    routerEvent$ = this.utilsService.getRouterEvents$();
    selectedCity$ = this.selectCityService.getSelectedCitySync();
    routerEventSelectCityCombined = combineLatest([this.routerEvent$, this.selectedCity$]).subscribe(
        ([routerEvent, { city }]) => {
            this.updateAuthModalRender(routerEvent.url);
            this.updateMenus(routerEvent.url, city);
        }
    );

    // Modal Render Variables
    renderVoAuthModal = false;
    renderMeetingRoomModal = false;

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    onMenuClick() {
        this.toggleMenuModal(false);
    }

    getFlexiConfig() {
        return this.flexiConfig;
    }

    getCorporateFlexiConfig() {
        return this.corporateFlexiConfig;
    }

    getDedicatedConfig() {
        return this.dedicatedConfig;
    }
    getManagedOfficeConfig() {
        return this.managedOfficeConfig;
    }
    getCommercialLeasingConfig() {
        return this.commercialLeasingConfig;
    }

    getVirtualOfficeConfig() {
        return this.virtualOfficeConfig;
    }

    getMeetingRoomConfig() {
        return this.meetingRoomConfig;
    }

    updateAuthModalRender(url) {
        this.renderVoAuthModal = this.headerService.renderVoAuthModal(url);
        this.renderMeetingRoomModal = this.headerService.renderMobileAuthModal(url);
    }

    updateMenus(url, city) {
        const headerConfig = this.headerService.getHeaderConfig(url, city);
        if (headerConfig) {
            this.selectedCity = city;
            const {
                menuConfig,
                menuConfigMobile,
                hideWhenCityNotSupportProduct,
                productObj,
                isLogoClickable,
                showCityChange,
                isAnimated,
                showMenuIconOnMobile,
                headerSearchConfig,
                locationWorkspaceSearchConfig,
            } = headerConfig;

            this.productObj = productObj;
            this.locationWorkspaceSearchConfig = locationWorkspaceSearchConfig;
            // On home and other generic pages product will be null
            // TODO should be recheked - whether we really want to show use dedicated as default product in these cases
            const productID = productObj ? productObj.productNameFrontend : 'DEDICATED';
            this.searchSolutionChangeHandler(productID);
            this.showHeaderSearch = Boolean(headerSearchConfig.showSearch && this.selectedCity);
            this.showHeaderSearchInSticky = headerSearchConfig.showDesktopSearchInStickyOnly;
            this.headerSearchVariant = headerSearchConfig.headerSearchVariant;
            // this.showHeaderMobileSearch = headerSearchConfig.showHeaderMobileSearch;
            this.headerSearchConfig = {
                ...this.headerSearchConfig,
                showProductSwitch: headerSearchConfig.showProductSwitch,
            };
            this.headerSearchConfig = {
                ...this.headerSearchConfig,
                locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
            };
            this.locationWorkspaceHeaderSearchMob = {
                showMobileHeaderSearch: this.showHeaderSearch,
                showProductSwitch: headerSearchConfig.showProductSwitch,
            };
            this.detailedHeaderSearchConfig = {
                ...this.detailedHeaderSearchConfig,
                selectedProduct: this.productObj ?? this.detailedHeaderSearchConfig.selectedProduct,
                products: headerSearchConfig.showProductSwitch
                    ? [
                          productsMap[productKeys.FLEXI],
                          productsMap[productKeys.DEDICATED],
                          productsMap[productKeys.VIRTUAL_OFFICE],
                          productsMap[productKeys.MEETING_ROOM],
                      ]
                    : [],
            };
            const selectedConfig = this.device.isMobile ? menuConfigMobile : menuConfig;
            // TODO :: Reduce complexity of this logic
            this.showCityChange = showCityChange;
            this.isLogoClickable = isLogoClickable;
            this.isAnimated = isAnimated;
            this.showMenuIconOnMobile = showMenuIconOnMobile;
            this.menus = this.menus.map(menu => {
                menu.show = this.getMenuVisibility(
                    menu,
                    selectedConfig,
                    hideWhenCityNotSupportProduct,
                    productObj,
                    city
                );
                if (menu.id === menuEnum.PRODUCTS) {
                    menu = this.updateProductInfo(menu, productObj, city);
                }
                if (menu.id === menuEnum.SEARCH && city && city.name) {
                    menu.title = `Search workspace in ${city.name}`;
                }
                return menu;
            });

            const unDisplayedMenu = this.menus.filter(menu => selectedConfig.indexOf(menu.id) < 0);
            const displayedMenu = selectedConfig.map(id => this.menus.find(menu => menu.id === id));
            this.menus = [...displayedMenu, ...unDisplayedMenu];
        }
    }

    getMenuVisibility(menu, selectedConfig, hideWhenCityNotSupportProduct, productObj, city) {
        if (productObj) {
            const isProductSupportedInCity = this.getIsProductSupporedByCity(productObj.productNameBackend, city);
            if (!isProductSupportedInCity && hideWhenCityNotSupportProduct.indexOf(menu.id) > -1) {
                return false;
            }
        }
        return selectedConfig.indexOf(menu.id) > -1 ? true : false;
    }

    updateProductInfo(menu: IMenuWithDropdownConfig, productObj, city) {
        if (productObj) {
            // finding on coping selected submenu info in menu in desktop
            if (!this.device.isMobile) {
                let selectedSubMenu: IMenuWithDropdownMenuItem;
                menu.subMenus.forEach(subMenu => {
                    subMenu.menuItems.forEach(menuItem => {
                        if (menuItem.id === productObj.productNameFrontend) {
                            selectedSubMenu = menuItem;
                        }
                    });
                });

                if (selectedSubMenu) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    (menu.title = selectedSubMenu?.title),
                        (menu.description = selectedSubMenu?.description),
                        (menu.imageUrl = selectedSubMenu?.imageUrl);
                }
                if (this.getIsProductSupporedByCity(productObj.productNameBackend, city)) {
                    menu.comingSoon = false;
                } else {
                    menu.comingSoon = true;
                }
            }
            // adding coming soon in submenu
            menu.subMenus.map(subMenu => {
                subMenu.menuItems.map(menuItem => {
                    const isProductSupportedByCity = this.getIsProductSupporedByCity(
                        productsMap[menuItem.id].productNameBackend,
                        city
                    );
                    if (isProductSupportedByCity) {
                        menuItem.comingSoon = false;
                    } else {
                        menuItem.comingSoon = true;
                    }
                });
            });
        }
        return menu;
    }

    toggleMenuModal(toggle: boolean) {
        this.menuModalMobile.isOpen = toggle;
    }

    onMenuBtnClick(id) {
        this.toggleMenuModal(false);
        this.headerService.setMenuData(id);
    }

    searchSolutionChangeHandler(productID: string) {
        const productTitles = {
            FLEXI: 'Flexi Desk',
            DEDICATED: 'Fixed Desk',
        };
        // update the menu config
        const updatedHeaderSearchSolutionsMenu = {
            ...this.searchSolutionsDropDownMenu,
            title: productTitles[productID],
            subMenus: [
                { ...this.getFlexiConfig(), isSelected: productID === menuEnum.FLEXI },
                { ...this.getDedicatedConfig(), isSelected: productID === menuEnum.DEDICATED },
            ],
        };
        // update the selected product
        this.selectedProduct = productsMap[productID];
        // update typeaheadConfig
        this.locationWorkspaceSearchConfig = {
            ...this.locationWorkspaceSearchConfig,
            product: this.selectedProduct,
        };
        this.headerSearchConfig = {
            ...this.headerSearchConfig,
            menu: updatedHeaderSearchSolutionsMenu,
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        };
    }

    onLocationWorkspaceSelectEvent(payload) {
        this.locationWorkspaceSearchService.onSelectionEvent(payload);
    }

    toggleModal(toggle: boolean) {
        this.locationWorkspaceSearchConfig = {
            ...this.locationWorkspaceSearchConfig,
            showSearchModal: toggle,
        };
        this.headerSearchConfig = {
            ...this.headerSearchConfig,
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        };
    }

    handleCollapse(menu: IMenuWithDropdownConfig) {
        this.menus.map(m => {
            if (m.type === menuType.DROPDOWN) {
                if (m.mobileSingleMenu) {
                    if (m.id === menu.id) {
                        m.collapsed = !m.collapsed;
                    }
                } else {
                    m.subMenus.forEach(subM => {
                        if (subM.id === menu.id) {
                            subM.collapsed = !subM.collapsed;
                        }
                    });
                }
            }

            return m;
        });
    }

    ngOnDestroy() {
        this.routerEventSelectCityCombined.unsubscribe();
    }

    getIsProductSupporedByCity(productName: string, city: ICity) {
        if (
            city &&
            city.products &&
            city.products.find(productSupportByCity => productSupportByCity.key === productName)
        ) {
            return true;
        }
        return false;
    }

    menuItemTrackBy(_index: number, item: (typeof this.menus)[number]) {
        return item.id;
    }

    getSubMenu(menu: IMenuWithDropdownConfig): IMenuWithDropdownSubMenu {
        const menuItems = menu.subMenus.reduce((acc, menu) => [...acc, ...menu.menuItems], []);

        return {
            id: menu.id,
            title: menu.title,
            description: menu.description,
            iconUrl: menu.iconUrl,
            collapsed: menu.collapsed,
            menuItems: menuItems,
        };
    }
}
